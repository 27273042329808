let projects = (images)=>{
    return (
        [
            {
              id: 1,
              brand: "Budweiser",
              slug: "messi644",
              images: images.Messi644,
              title: "Messi 644",
              description: `Lionel Messi, la nueva cara de Budweiser, estaba a punto de batir el récord de 643 goles en club de Pele. Para poder promocionar la incorporación de Messi a Budweiser, se aprovechó esta ocasión para crear un prode digital para apostar de qué manera iba a hacer Messi sus últimos 5 goles antes de batir el récord.`,
              content1: `Se diseñó y desarrolló una plataforma para Budweiser en tiempo record. Se diseñó y desarrolló una plataforma para Budweiser en tiempo record.`,
              content2: `Se diseñó y desarrolló una plataforma para Budweiser en tiempo record. Se diseñó y desarrolló una plataforma para Budweiser en tiempo record.`,
              filters: ["UX","UI","Desarrollo Web","Desarrollo Mobile"],
              tags: [
                "UX / UI",
                "Desarrollo"
              ],
              year: "2020",
              country: "Argentina",
            },
            {
              id: 2,
              brand: "Blasfemia",
              images: images.Blasfemia,
              slug: "blasfemia",
              title: "Blasfemia",
              description: `Blasfemia es un vino en lata que nace para derribar los mitos tradicionales de la industria y revolucionar la manera en que consumimos vino. La marca confió en Itesa para diseñar y desarrollar su sitio web institucional. El desafío del proyecto fue crear un diseño utilizando recursos modernos y a la vez disruptivos, intentando mantener el estilo provocativo de la marca.`,
              content1: ` El desafío del proyecto fue crear un diseño utilizando recursos modernos y a la vez disruptivos, intentando mantener el estilo provocativo de la marca.`,
              content2: ` El desafío del proyecto fue crear un diseño utilizando recursos modernos y a la vez disruptivos, intentando mantener el estilo provocativo de la marca.`,
              filters: ["UX","UI","Desarrollo Web","Desarrollo Mobile"],
              tags: ["UX / UI", "Desarrollo"],
              year: "2020",
              country: "Argentina",
            },
            {
              id: 3,
              brand: "Escappe",
              images: images.Escappe,
              slug: "escappe",
              title: "Escappe",
              description: `Escappe es una app diseñada para reinventar la industria del entretenimiento digital en Guatemala. La app permite ganar dinero a los usuarios a través de trivias y juegos en vivo con anfitriones. Escape es también un espacio en donde las marcas pueden pautar contenido publicitario de una manera novedosa, auspiciando las trivias.`,
              content1: `En colaboración con Seeds, se diseñó y desarrolló la app en tiempo record. Se diseñó y desarrolló una plataforma para Budweiser en tiempo record.`,
              content2: `En colaboración con Seeds, se diseñó y desarrolló la app en tiempo record. Se diseñó y desarrolló una plataforma para Budweiser en tiempo record.`,
              filters: ["UX","UI","Desarrollo Web","Desarrollo Mobile"],
              tags: ["UX / UI", "Desarrollo"],
              year: "2020",
              country: "Argentina",
            },
            {
              id: 4,
              brand: "Conmebol",
              images: images.JuntosPorSudamerica,
              slug: "marketplaceConmebol",
              title: "Marketplace Conmebol",
              description: `Juntos Por Sudamerica es un e-commerce que nace con el objetivo de recaudar fondos para afrontar al Covid - 19 para una acción de la Conmebol junto con la Cruz Roja.  Se diseñó y desarrolló un e-commerce para la campaña en el cual se comercializaban rifas para sortear artículos donados por famosos.`,
              content1: `El cliente del proyecto fue la Conmebol junto con la Cruz Roja y se trabajó en conjunto con el equipo de Paypal Latam y Google Cloud Latam.`,
              content2: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat`,
              filters: ["UX","UI","Desarrollo Web","Desarrollo Mobile"],
              tags: ["UX / UI", "Desarrollo"],
              year: "2020",
              country: "Argentina",
            },
            {
              id: 5,
              brand: "Budweiser",
              images: images.Lollapalooza,
              slug: "lollapaloozaRoadmap",
              title: "Lollapalooza Roadmap",
              description: `Con el fin de promocionar el festival y poder brindar a los espectadores una mejor experiencia, se creó una plataforma con un algoritmo integrado con Spotify que según los intereses de los usuarios, recomienda un Roadmap para cada día del evento.`,
              content1: `El Roadmap le muestra a los usuarios una recomendación de a qué bandas ver y en qué momento, según los intereses personales.`,
              content2: `Para este proyecto se trabajó para la marca Budweiser, en colaboración con Digital House.`,
              filters: ["UX","UI","Desarrollo Web","Desarrollo Mobile"],
              tags: ["UX / UI", "Desarrollo", "Project Management"],
              year: "2020",
              country: "Argentina",
            },
            {
              id: 6,
              brand: "Cerveza Patagonia",
              images: images.Patagonia,
              slug: "patagoniaSite",
              title: "Cerveza Patagonia Site",
              description: `Cerveza Patagonia, marca global de ABInBev, tenía la necesidad de relanzar y modernizar su
              sitio web institucional. Para este proyecto se trabajó en equipo con la agencia creativa Draftline,
              quienes elaboraron los diseños del sitio.`,
              content1: `Además de desarrollar el nuevo sitio web, la marca tenía la necesidad de contar con un panel administrador del sitio para poder dar de alta Refugios y Variedades de cerveza sin contar con un equipo de soporte.`,
              content2: `Gracias al CMS que Itesa desarrolló a medida, hoy en día el sitio se actualiza de manera muy simple por la marca accediendo a un portal.`,
              filters: ["Desarrollo Web","Desarrollo Mobile"],
              tags: ["Desarrollo"],
              year: "2020",
              country: "Argentina",
            }
          ]
    )
} 
export default  projects 